<template>
  <div class="card-container contact-us-setting">
    <SectionTitle
      title="聯絡我們"
      btn="編輯"
      @edit="showDialog=true"
    />

    <el-form label-position="left" label-width="100px">
      <el-form-item label="營業時間">
        {{ findData('shopOpenTime') }}
      </el-form-item>
      <el-form-item label="聯絡電話">
        {{ findData('shopPhone') }}
      </el-form-item>
      <el-form-item label="聯絡地址">
        {{ findData('shopAddress') }}
      </el-form-item>
    </el-form>
    <ContactUsEditDialog v-if="showDialog" :config="contactUsConfig" @close="showDialog=false" @confirm="$emit('refresh')" />
  </div>
</template>

<script>
import ContactUsEditDialog from '@/views/Ecommerce/Dialog/ContactUsEditDialog.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { find } from 'lodash'

export default {
  name: 'FAQ',
  components: { SectionTitle, ContactUsEditDialog },
  props: ['contactUsConfig', 'pages'],
  data: () => ({
    openDialog: false,
    formData: {},
    showDialog: false,
  }),

  methods: {
    findData (key) {
      const exist = find(this.contactUsConfig, { name: key })
      if (exist) return exist.text
      return ''
    },
  },
}
</script>

<style lang="postcss" scoped>
.contact-us-setting {
  @apply px-[32px] py-[24px];
}
</style>
