<template>
  <el-dialog
    title="聯絡我們"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" label-position="left" label-width="150px" :model="formData" :rules="formRules">
      <el-form-item label="營業時間">
        <el-input v-model="formData.shopOpenTime" show-word-limit />
      </el-form-item>
      <el-form-item label="聯絡電話" prop="shopPhone">
        <el-input v-model="formData.shopPhone" show-word-limit />
      </el-form-item>
      <el-form-item label="聯絡地址">
        <el-input v-model="formData.shopAddress" show-word-limit />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="confirm">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { UpdateFooterSetting } from '@/api/ecommerce/footerSetting'
import formUtils from '@/utils/form'
import { phoneRules } from '@/validation'
import { map, find, forEach } from 'lodash'
export default {
  name: 'ContactUsEditDialog',
  props: ['config'],
  data: () => ({
    formData: {
      shopOpenTime: '',
      shopPhone: '',
      shopAddress: '',
    },

    formRules: {
    },
  }),
  computed: {
    ...mapGetters(['shop']),

    composeData () {
      const list = []
      forEach(this.formData, (content, key) => {
        if (content) {
          list.push({
            type: 'text',
            text: content,
            name: key,
          })
        }
      })
      return list
      // return map(this.formData, (content, key) => ({
      //   type: 'text',
      //   text: content,
      //   name: key
      // }))
    },
  },
  mounted () {
    forEach(this.formData, (item, key) => {
      const exist = find(this.config, { name: key })
      if (exist) this.formData[key] = exist.text
    })
  },
  methods: {
    async confirm () {
      if (!await this.updateNavConfig()) return
      this.$emit('confirm')
      this.$emit('close')
    },

    cancel () {
      this.$emit('cancel')
      this.$emit('close')
    },

    async updateNavConfig () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const [, err] = await UpdateFooterSetting({
        shopId: this.shop,
        contactUs: this.composeData,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      return true
    },

  },
}
</script>

<style></style>
