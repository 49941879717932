<template>
  <div class="footer-setting">
    <p class="page-title">底部功能設定</p>
    <div v-if="loaded" class="footer-setting-wrapper">
      <AboutUs :aboutUsConfig="aboutUsConfig" :pages="pages" @refresh="refresh" />
      <FAQ :faqConfig="faqConfig" :pages="pages" @refresh="refresh" />
      <ContactUs :contactUsConfig="contactUsConfig" @refresh="refresh" />
    </div>
  </div>
</template>

<script>
import FAQ from './components/FAQ.vue'
import AboutUs from './components/AboutUs.vue'
import ContactUs from './components/ContactUs.vue'
import { GetFooterSetting } from '@/api/ecommerce/footerSetting'
import { GetPageSetting } from '@/api/ecommerce/pageSetting'
import { mapGetters } from 'vuex'

export default {
  name: 'FooterSetting',
  components: {
    AboutUs,
    FAQ,
    ContactUs,
  },
  data: () => ({
    footerConfigData: null,
    pages: [],
    loaded: false,
  }),
  computed: {
    ...mapGetters(['shop']),
    aboutUsConfig () {
      return this.footerConfigData?.aboutUs || {}
    },
    faqConfig () {
      return this.footerConfigData?.faq || {}
    },
    contactUsConfig () {
      return this.footerConfigData?.contactUs || {}
    },
  },
  async mounted () {
    this.loaded = false
    await this.getFooterSetting()
    await this.getPageSetting()
    this.loaded = true
  },
  methods: {
    async refresh () {
      await this.getFooterSetting()
      await this.getPageSetting()
    },

    async getFooterSetting () {
      const [res, err] = await GetFooterSetting({ shopId: this.shop })
      if (err) return
      this.footerConfigData = res
    },

    async getPageSetting () {
      const [res, err] = await GetPageSetting({ shopId: this.shop })
      if (err) return
      this.pages = res
    },
  },
}
</script>

<style lang="postcss" scoped>
.footer-setting-wrapper {
  @apply grid gap-[24px];
}

.page-title {
  @apply font-medium text-[24px] mb-[30px];
}
</style>
