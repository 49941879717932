<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    width="670px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <template slot="title">
      <p class="page-title">{{ dialogTitle }}</p>
      <p class="hint">*數量限制最多三個，其他頁面請至「其他頁面設定」進行設定。</p>
    </template>

    <el-form v-if="Object.keys(formData).length" ref="form" :model="formData" :rules="formRules" label-position="top">
      <el-form-item v-for="(config, key, index) in formData" :key="key" :label="`排序${index+1}`">
        <FormOptions
          :objKey="key"
          :activitys="activitys"
          :pages="pages"
          :configData="formData[key]"
          @update="updateConfigData($event, key)"
        />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="cancel">取消</el-button>
      <el-button type="primary" @click="updateNavConfig">儲存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FormOptions from './FormOptions.vue'
import { UpdateFooterSetting } from '@/api/ecommerce/footerSetting'
import { clone, forEach, remove } from 'lodash'
import { mapGetters } from 'vuex'
import { noEmptyRules, urlRules } from '@/validation'
import formUtils from '@/utils/form'

export default {
  name: 'FAQSettingDialog',
  components: { FormOptions },
  props: ['configData', 'activitys', 'pages', 'type'],
  computed: {
    ...mapGetters(['shop']),
    compactData () {
      const list = []
      forEach(this.formData, item => {
        if (item) list.push(item)
      })
      remove(list, i => !i.type)
      return list
    },
    dialogTitle () {
      let title = ''
      if (this.type === 'faq') title = '常見問題設定'
      if (this.type === 'aboutUs') title = '關於我們設定'
      return title
    },
  },
  data: () => ({
    config: null,
    formData: {},
    formRules: {},
  }),
  async mounted () {
    this.generateFormRules()
    this.syncConfigFormData()
  },
  methods: {
    cancel () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('close')
    },
    async updateNavConfig () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      const [, err] = await UpdateFooterSetting({
        shopId: this.shop,
        aboutUs: this.type === 'aboutUs' ? this.compactData : undefined,
        faq: this.type === 'faq' ? this.compactData : undefined,
      })
      if (err) return this.$message.error(err)
      this.$message.success('更新成功！')
      this.$emit('refresh')
      this.$emit('close')
    },
    updateConfigData (e, key) {
      this.formData[key] = e
    },
    generateFormRules () {
      for (let c = 0; c < 3; c++) {
        this.$set(this.formRules, `config${c}.name`, [noEmptyRules()])
        this.$set(this.formRules, `config${c}.url`, [noEmptyRules(), urlRules()])
        this.$set(this.formRules, `config${c}.ecommercePageId`, [noEmptyRules()])
      }
    },
    syncConfigFormData () {
      const config = clone(this.configData)
      for (let c = 0; c < 3; c++) {
        this.$set(this.formData, `config${c}`, config[c] || {})
      }
    },

  },
}
</script>

<style lang="postcss" scoped>
::v-deep .el-dialog__body {
  @apply py-0;
}
::v-deep .el-select {
  @apply !w-full;
}
::v-deep .el-input {
  @apply !w-full;
}

.page-title {
  @apply text-[24px] font-medium;
}

.hint {
  @apply text-sub text-gray-80 font-medium;
}
</style>
